



import { useState, useEffect } from "react";
import MKTypography from "components/MKTypography"; 
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import MKAlert from "components/MKAlert";
import CloseIcon from "@mui/icons-material/Close";
import Dropdowns from "components/AppKey/Dropdowns";
import { appAuth } from "../../contexts/AppContext"
 
import { Link, useNavigate } from "react-router-dom"

export default function AppOption({app}) { 

    const navigate = useNavigate() 
    const [errorMessage, setErrorMessage] = useState("");
    const [relyPartyId, setReplyPartyId] = useState("");
    const [appData, setAppData] = useState(app)
    const [showTestEmailExt, setTestEmailExt] = useState(false);
    const [showTestSMSExt, setTestSMSExt] = useState(false);
    const [showUpgradePlan, setUpgradePlan] = useState(false);

    const { loggedInUser, testAppEmailExt, testAppSMSExt, updateApp, setLoadingSuccessData, currentApp, setCurrentApp, checkURL} = appAuth()

    const toggleEmailExtModal = () => setTestEmailExt(!showTestEmailExt);
    const toggleSMSExtModal = () => setTestSMSExt(!showTestSMSExt);
    const toggleUpgradePlanModal = () => setUpgradePlan(!showUpgradePlan);

    const updateDomains = async (option) => { 
        setCurrentApp({
            ...currentApp,
            [option]: appData[option] 
        })
        
        let app = await updateApp(appData.appId, option, appData[option]) 
         

        console.log("updateAppData app " , app)
    }

    useEffect(() => {  
        if(app) setReplyPartyId(app.relyPartyId) 
    }, [])


    const checkAppDomain = async () => { 
        let check  = await checkURL(appData.relyPartyId)
        console.log("checkAppDomain check " , check)
    }

    

    const updateAppData = async (value, option) => {  

        if(option == "handleType" && value != "email" && loggedInUser.planId === "free-plan"){ 
            toggleUpgradePlanModal()
            return;
        }

        if(value === currentApp[option]) return
        
        setAppData({
            ...appData,
            [option]: value
        })

        setCurrentApp({
            ...currentApp,
            [option]: value
        })
        
        let app = await updateApp(appData.appId, option, value)

        console.log("updateAppData app " , app)

        
    }

    const onChangeValue = async (evt) => { 

        console.log("onChangeValue evt.target.name " , evt.target.name)

        let value =  evt.target.value
       
        if(evt.target.name === "relyPartyId"){
           
            if( value !== "" && value.indexOf("https://") < 0 ) value = `https://${value}`;
            setReplyPartyId(value)
        } 



        setAppData({
            ...appData,
            [evt.target.name]: value
        })

    }
 

    const updateSendGridHandler = () => { 

        updateAppData(appData.sgKey, "emailExtensionAPIKey")
        updateAppData(appData.sgEmail, "emailExtensionSenderEmail")
    }


    const updateSMSHandler = () => { 

        updateAppData(appData.TWILIOAccountSid, "TWILIOAccountSid")
        updateAppData(appData.TWILIOToken, "TWILIOToken")
        updateAppData(appData.TWILIOPhoneNumber, "TWILIOPhoneNumber")
    }


    const testSendSMS = async () => {
        
        let result = await testAppSMSExt(app.appId, appData.testedNumber) 
        if (result === true) setLoadingSuccessData({message:"Your tested sms has been sent."})
        else if(result && result.error) setErrorMessage(result.error.message)
    }

    const clickUpgradeAccount = async () => {
        navigate(`/profile?showPlan=true`) 
    }


    const testSendGridEmail = async () => {
        let result = await testAppEmailExt(app.appId, appData.testedEmail) 
        if (result === true) setLoadingSuccessData({message:"Your tested email has been sent."})
        else if(result && result.error) setErrorMessage(result.error.message)
    }


    return( 
        <MKBox p={2}>
            <MKTypography variant="h5" >
                Application Options
            </MKTypography>
        
            <Container>
                
                <Grid container spacing={3} mt={2}  pb={5}>
                    <Grid item xs={12} md={5} lg={5}>
                        <Stack spacing={3}>
                            

                            <MKBox>
                                <MKTypography variant="h6" color="text">
                                APP USER NAME LOGIN:
                                </MKTypography>

                                <MKTypography variant="body2" color="text"> 
                                Turn on/off App user name login
                                </MKTypography>

                                <MKBox display="flex" alignItems="center">
                                    <Switch checked={appData.userNamesEnabled} onChange={() => updateAppData(!appData.userNamesEnabled, "userNamesEnabled")} />
                                    <MKTypography
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    ml={1}
                                    // sx={{ cursor: "pointer", userSelect: "none" }}
                                    // onClick={toggleUsernameSwitch}
                                    >
                                    Allow App user name login
                                    </MKTypography>
                                </MKBox> 
                            </MKBox>

                            <MKBox>
                                <MKTypography variant="h6" color="text">
                                ALLOW ANONYMOUS LOGIN
                                </MKTypography>

                                <MKTypography variant="body2" color="text"> 
                                Turn on/off App anonymous login
                                </MKTypography>

                                <MKBox display="flex" alignItems="center">
                                    <Switch checked={appData.anonymousLoginEnabled}  onChange={() => updateAppData(!appData.anonymousLoginEnabled, "anonymousLoginEnabled")}/>
                                    <MKTypography
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    ml={1}
                                    // sx={{ cursor: "pointer", userSelect: "none" }}
                                    // onClick={toggleAnonLoginSwitch}
                                    >
                                    Allow App anonymous login
                                    </MKTypography>
                                </MKBox> 
                            </MKBox>

                            <MKBox>
                                <MKTypography variant="h6" color="text">
                                ALLOW APPLE LOGIN
                                </MKTypography>

                                <MKTypography variant="body2" color="text"> 
                                Turn on/off Apple login
                                </MKTypography> 
                                

                                <MKBox display="flex" alignItems="center">
                                    <Switch checked={appData.appleLoginEnabled}  onChange={() => updateAppData(!appData.appleLoginEnabled, "appleLoginEnabled")}/>
                                    <MKTypography
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    ml={1}
                                    // sx={{ cursor: "pointer", userSelect: "none" }}
                                    // onClick={toggleAnonLoginSwitch}
                                    >
                                    Allow Apple login: {appData.appleLoginEnabled ? "on" : "off"}
                                    </MKTypography>
                                    
                                </MKBox>  

                                {appData.appleLoginEnabled && 
                                    <MKBox pb={2}> 
                                        <MKInput
                                            variant="standard"
                                            label="Enter Apple Bundle Identifier (comma seperated for multiple value)"
                                            placeholder="eg: io.appkey.appdemo" 
                                            name='appleBundleId' defaultValue={appData.appleBundleId} onChange={onChangeValue}
                                            fullWidth
                                        />

                                        <MKButton variant="gradient" color="primary" onClick={() => updateAppData(appData.appleBundleId, "appleBundleId")} sx={{ marginTop: 2 }}>
                                            Save
                                        </MKButton>
                                    </MKBox> 
                                }
                            </MKBox>

                            <MKBox>
                                <MKTypography variant="h6" color="text">
                                ALLOW GOOGLE LOGIN
                                </MKTypography>

                                <MKTypography variant="body2" color="text"> 
                                Turn on/off Google login
                                </MKTypography>

                                <MKBox display="flex" alignItems="center">
                                    <Switch checked={appData.googleLoginEnabled}  onChange={() => { updateAppData(!appData.googleLoginEnabled, "googleLoginEnabled")} }/>
                                    <MKTypography
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    ml={1}
                                    // sx={{ cursor: "pointer", userSelect: "none" }}
                                    // onClick={toggleAnonLoginSwitch}
                                    >
                                    Allow Google login: {appData.googleLoginEnabled ? "on" : "off"}
                                    </MKTypography>
                                </MKBox> 
 
                                {appData.googleLoginEnabled && 
                                    <MKBox pb={2}> 
                                        <MKInput
                                            variant="standard"
                                            label="Enter Google Client ID  (comma seperated for multiple value)"
                                            placeholder="eg: 133176442195-0oj2rat8l9o8n5jq535ss3s7b0t9gt0a.apps.googleusercontent.com" 
                                            name='googleClientId' defaultValue={appData.googleClientId} onChange={onChangeValue}
                                            fullWidth
                                        />

                                        <MKButton variant="gradient" color="primary" onClick={() =>{ updateAppData(appData.googleClientId, "googleClientId")} } sx={{ marginTop: 2 }}>
                                            Save
                                        </MKButton>
                                    </MKBox> 


                                }
                            </MKBox> 
                           

                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={2} lg={2}>

                    </Grid>

                    <Grid item xs={12} md={5} lg={5} >

                    <Stack spacing={3}> 


                            <MKBox>
                                <MKBox alignItems="center" justifyContent="left" display="flex" >
                                    <MKTypography variant="h6" color="text">
                                    APP SIGNUP
                                    </MKTypography>

                                    <Dropdowns items={["OPEN",  "INVITE"]} defaultValue = { appData.signup.toUpperCase()} onChange={(val) => { 
                                        updateAppData(val.toLowerCase(), "signup")
                                    }} />

                                </MKBox>

                                
                            </MKBox>

                            <MKBox>
                                <MKBox alignItems="center" justifyContent="left" display="flex" >
                                    <MKTypography variant="h6" color="text">
                                    HANDLE TYPE
                                    </MKTypography>

                                    <Dropdowns items={loggedInUser.planId === "free-plan" ? ["EMAIL"] : ["EMAIL",  "PHONE", "BOTH"]} defaultValue = {appData.handleType.toUpperCase()} onChange={(val) => { 
                                        updateAppData(val.toLowerCase(), "handleType")
                                    }} />

                                </MKBox>
                                
                                {/* 
                                appData.handleType !== "email" &&  
                                <MKBox pt={2}>
                                    <MKTypography variant="h6" color="text">
                                        SMS EXTENSIONS
                                    </MKTypography>

                                    <MKTypography variant="body2" color="text"> 
                                    Set your own Twilio sms extensions for your application.
                                    </MKTypography>

                                    <MKBox display="flex" alignItems="center">
                                        <Switch checked={appData.smsExtension} onChange={() => updateAppData(!appData.smsExtension, "smsExtension")} />
                                        <MKTypography
                                        variant="button"
                                        color="text"
                                        fontWeight="regular"
                                        ml={1}
                                        // sx={{ cursor: "pointer", userSelect: "none" }}
                                        // onClick={toggleEmailExtSwitch}
                                        >
                                        Enable SMS Extensions
                                        </MKTypography>
                                    </MKBox> 
                                    {appData.smsExtension &&  <MKBox>
                                        <MKInput
                                            variant="standard"
                                            label="Enter TWilio Account SID"
                                            placeholder="Account SID" 
                                            name='TWILIOAccountSid' defaultValue={appData.TWILIOAccountSid} onChange={onChangeValue}
                                            fullWidth
                                        />
                                        <MKInput
                                            variant="standard"
                                            label="Enter TWilio Auth Token"
                                            placeholder="Token Key" 
                                            name='TWILIOToken' defaultValue={appData.TWILIOToken} onChange={onChangeValue}
                                            fullWidth
                                        />

                                        <MKInput
                                            variant="standard"
                                            label="Enter TWilio Phone Number"
                                            placeholder="Phone Number: +12029527902" 
                                            name='TWILIOPhoneNumber' defaultValue={appData.TWILIOPhoneNumber} onChange={onChangeValue}
                                            fullWidth
                                        />

                                        <Stack direction="row" spacing={2} mt={2}>
                                            
                                            <MKButton variant="gradient" color="primary" onClick={updateSMSHandler}>
                                                Save
                                            </MKButton>
                                            
                                            <MKButton variant="gradient" color="primary" onClick={toggleSMSExtModal}>
                                                Test
                                            </MKButton>

                                        </Stack>
                                    </MKBox>
                                    }
                                    
                                </MKBox>

                                */}
                                
                            </MKBox>
                             
                         
                            <MKBox>
                                <MKBox alignItems="center" justifyContent="left"  >
                                    <MKTypography variant="h6" color="text">SET RELYING PARTY ID</MKTypography>
                                    <MKTypography variant="caption" color="info">Must be set if you use your own domain (comma seperated for multiple value)</MKTypography>
                                    <MKBox mb={2}>
                                        <MKInput
                                            variant="standard"
                                            label="Enter Rely Party Id"
                                            placeholder="eg: https://web.appkey.io, https://appkey.io" 
                                            name='relyPartyId' defaultValue={appData.relyPartyId} onChange={onChangeValue}
                                            fullWidth
                                        />
                                        {relyPartyId !== "" && relyPartyId !== undefined &&
                                        <MKBox>
                                            <MKTypography variant="caption" color="info">Make sure this link is working and showing your JSON data if you  host your Apple App Site Association json file:</MKTypography>
                                            <a  rel="noopener" href={`${relyPartyId}/apple-app-site-association`} target="_blank">
                                                <MKTypography variant="body2" color="info">{`${relyPartyId}/apple-app-site-association`}</MKTypography>
                                            </a>

                                            <MKTypography variant="body2" color="text">Or</MKTypography>

                                            <a  rel="noopener" href={`${relyPartyId}/.well-known/apple-app-site-association`} target="_blank">
                                                <MKTypography variant="body2" color="info">{`${relyPartyId}/.well-known/apple-app-site-association`}</MKTypography>
                                            </a>
                                        </MKBox> 
                                        }
                                    </MKBox> 

                                   
                                      
                                    <MKButton variant="gradient" color="primary" onClick={ () => updateDomains('relyPartyId')}>
                                        Save
                                    </MKButton>
                                    

                                </MKBox>

                                <MKBox alignItems="center" justifyContent="left"  mt={2}>
                                    <MKTypography variant="h6" color="text">SET ANDROID APK ID</MKTypography>
                                    <MKTypography variant="caption" color="info">For your android application</MKTypography>
                                    <MKBox mb={2}>
                                        <MKInput
                                            variant="standard"
                                            label="Enter android:apk-key-hash:androidCertFingerprint"
                                            placeholder="eg: android:apk-key-hash:androidCertFingerprint" 
                                            name='androidApkKey' defaultValue={appData.androidApkKey} onChange={onChangeValue}
                                            fullWidth
                                        />
                                         
                                    </MKBox> 

                                   
                                      
                                    <MKButton variant="gradient" color="primary" onClick={ () => updateDomains('androidApkKey')}>
                                        Save
                                    </MKButton>
                                    

                                </MKBox>
                            </MKBox> 

                            <MKBox>
                                <MKTypography variant="h6" color="text">
                                    EMAIL EXTENSIONS
                                </MKTypography>

                                <MKTypography variant="body2" color="text"> 
                                Set your own email extensions for your application.
                                </MKTypography>

                                <MKBox display="flex" alignItems="center">
                                    <Switch checked={appData.emailExtension} onChange={() => updateAppData(!appData.emailExtension, "emailExtension")} />
                                    <MKTypography
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    ml={1}
                                    // sx={{ cursor: "pointer", userSelect: "none" }}
                                    // onClick={toggleEmailExtSwitch}
                                    >
                                    Enable Email Extensions
                                    </MKTypography>
                                </MKBox> 
                                {appData.emailExtension &&  
                                    <MKInput
                                        variant="standard"
                                        label="Enter SendGrid API Secret Key"
                                        placeholder="API Secret Key" 
                                        name='sgKey' defaultValue={appData.sgKey} onChange={onChangeValue}
                                        fullWidth
                                    />
                                }
                                
                            </MKBox>
                            {appData.emailExtension &&   

                            <MKBox container >
                                <MKBox> 
                                    <MKTypography variant="h6" color="text">
                                        SendGrid Sender Email
                                    </MKTypography>

                                    <MKTypography variant="body2" color="text"> 
                                    Enter Sender Email (This email will be used to send email to user. Make sure you verifiy sender identity with SendGrid)
                                    </MKTypography>


                                    <MKInput
                                        variant="standard"
                                        label="Enter SendGrid Sender Email"
                                        placeholder="email"
                                        type="email"
                                        name='sgEmail' defaultValue={appData.sgEmail}
                                        fullWidth
                                    />
                                </MKBox>

                                <Grid container spacing={3} pt={3}>
                                    <Grid item xs={6} md={4} lg={4}>
                                        
                                        <MKButton variant="gradient" color="primary" onClick={updateSendGridHandler}>
                                            Save
                                        </MKButton>
                                    </Grid>

                                    <Grid item xs={6} md={4} lg={4}></Grid>

                                    <Grid item xs={6} md={4} lg={4}>
                                        {appData.sgKey &&  
                                            <MKButton variant="gradient" color="info" onClick={toggleEmailExtModal}>
                                                test extension
                                            </MKButton>
                                        }
                                    </Grid>
                                </Grid>
                            </MKBox>

                            }

                            

                        </Stack>
                    </Grid>
                    
                </Grid> 
            </Container>




                                        
            <Modal open={showTestEmailExt} onClose={toggleEmailExtModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={showTestEmailExt} timeout={300}>
                <MKBox
                position="relative"
                sx={{ maxWidth: 'md' }}
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
                >
                <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                    <MKTypography variant="h5">Test Email Extensions</MKTypography>
                    <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleEmailExtModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                    <MKTypography variant="body">Please enter an email for sending a tested email.</MKTypography>

                    <MKInput name="testedEmail" variant="standard" label="Send to email" placeholder="you@yourmail.com"
                    onChange={onChangeValue} 
                    required  
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    />
                

                </MKBox>
                <Divider sx={{ my: 0 }} />

                {errorMessage  && <MKAlert color="error" >Whoop! {errorMessage}</MKAlert>}

                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                    <MKButton variant="gradient" color="dark" onClick={toggleEmailExtModal}>
                    cancel
                    </MKButton>
                    <MKButton variant="gradient" color="primary" onClick={testSendGridEmail}>
                    Submit
                    </MKButton>
                </MKBox>
                </MKBox>
            </Slide>
            </Modal>

                                    
            <Modal open={showTestSMSExt} onClose={toggleSMSExtModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={showTestSMSExt} timeout={300}>
                <MKBox
                position="relative"
                sx={{ maxWidth: 'md' }}
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
                >
                <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                    <MKTypography variant="h5">Test SMS Extensions</MKTypography>
                    <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleSMSExtModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                    <MKTypography variant="body">Please enter a phone for sending a tested sms.</MKTypography>

                    <MKInput name="testedNumber" variant="standard" label="Phone Number" placeholder="+12029527902"
                    onChange={onChangeValue} 
                    required  
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    />
                

                </MKBox>
                <Divider sx={{ my: 0 }} />

                {errorMessage  && <MKAlert color="error" >Whoop! {errorMessage}</MKAlert>}

                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                    <MKButton variant="gradient" color="dark" onClick={toggleSMSExtModal}>
                    cancel
                    </MKButton>
                    <MKButton variant="gradient" color="primary" onClick={testSendSMS}>
                    Submit
                    </MKButton>
                </MKBox>
                </MKBox>
            </Slide>
            </Modal>



           
            <Modal open={showUpgradePlan} onClose={toggleUpgradePlanModal} sx={{ display: "grid", placeItems: "center" }}>
                <Slide direction="down" in={showUpgradePlan} timeout={300}>
                    <MKBox
                    position="relative"
                    sx={{ maxWidth: 'md' }}
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                    >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Upgrade Account</MKTypography>
                        <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleUpgradePlanModal} />
                    </MKBox>
                    <Divider sx={{ my: 0 }} />
                    <MKBox p={2}>
                        <MKTypography variant="body">Please upgrade your account to use this feature.</MKTypography>

                    </MKBox>
                    <Divider sx={{ my: 0 }} /> 

                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="dark" onClick={toggleUpgradePlanModal}>
                        cancel
                        </MKButton>
                        <MKButton variant="gradient" color="primary" onClick={clickUpgradeAccount}>
                        Upgrade
                        </MKButton>
                    </MKBox>
                    </MKBox>
                </Slide>
            </Modal>



        </MKBox>
    )


}