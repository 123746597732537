/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";
 
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox"; 
import MKTypography from "components/MKTypography"; 
import Table from "components/Table";
import MKInput from "components/MKInput";
import Modal from "@mui/material/Modal";
import MKButton from "components/MKButton"; 
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import MKAlert from "components/MKAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"; 
import { Link, useNavigate } from "react-router-dom"
import AppLayout from "components/Layouts/AppLayout";
import { useAuth } from "../contexts/AuthContext"
import {startAuthentication, startRegistration} from '@simplewebauthn/browser';


function Passkey() {

  const renderRef = useRef(false) 
  const {removePasskey, verify, verifyComplete, addPasskey, addPasskeyComplete, inviteAddPasskey, updatePasskey, getUser } = useAuth()
  const [profile, setProfile] = useState({})
  const navigate = useNavigate() 
  const loggedInUser = JSON.parse(localStorage.getItem("appkey.io.user"));  
  if (!loggedInUser || !loggedInUser["access-token"]) navigate("/signin")
 
  const [passkeyRenderData, setPasskeyRenderData] = useState([]);
  const [passkeyData, setPasskeyData] = useState([]);
  const [key, setKey] = useState({});
  const [inputData, setInputData] = useState({});
  const [error, setError] = useState()
  const [infoMessage, setInfoMessage] = useState()
  
  const [modalstate, setModalState] = useState({ 
    showInviteAddKey:false,
    showAddKey:false,
    showEditKey:false,
    showDeleteKey:false,
    verifyAccount:true
  }); 


  useEffect(() => { 
    renderPasskey()

  },[passkeyData])

  useEffect(() => { 

    if (!loggedInUser || loggedInUser === undefined || !loggedInUser.authenticators){
      navigate("/signin")
      return
    } 

    async function fetchUser() {  

      let user = await getUser();
      if(user.error){

      }
      else {
        localStorage.setItem('user', JSON.stringify(user));  
        setPasskeyData(user.authenticators)
      }

    }

    if (renderRef.current === false) {
      setPasskeyData(loggedInUser.authenticators)
     
      fetchUser()
      return () => {
        renderRef.current = true
        console.log("Passkey : render clean up. ")
      } 
    }




  }, [])

  const renderPasskey = () => {

    setPasskeyRenderData([]) 

    if(passkeyData === undefined || passkeyData.length === 0) return

    for (let index = 0; index < passkeyData.length; index++) {
            
        let item = makeRecord(passkeyData[index]); 
        item.number = (index + 1 )
        
        if (passkeyData.length > 1){
          item.control =  <Stack direction="row" spacing={2}>
                        
                            <MKButton variant="outlined" color="info" onClick={() => toggleEditKeyModal(item)} px={2} iconOnly> <Icon >draw</Icon> </MKButton>

                            <MKButton variant="outlined" color="error" onClick={() => toggleDeleteKeyModal(item)} px={2} iconOnly><Icon >delete</Icon></MKButton>
                         
                          </Stack>
        }
        else {
          item.control =  <Stack direction="row" spacing={2}> 
                            <MKButton variant="outlined" color="info" onClick={() => toggleEditKeyModal(item)} px={2} iconOnly> <Icon >draw</Icon> </MKButton> 
                          </Stack>
        }

        setPasskeyRenderData(prevItems => { 
            return [...prevItems, item];
        });   
       

    }
  }

  const updatePasskeyTable = (key) => {

    let item = makeRecord(key); 
    item.number = (passkeyRenderData.length + 1)

    item.control =  <Stack direction="row" spacing={2}>
                            
      <MKButton variant="outlined" color="info" onClick={() => toggleEditKeyModal(item)} px={2} iconOnly> <Icon >draw</Icon> </MKButton>

      <MKButton variant="outlined" color="error" onClick={() => toggleDeleteKeyModal(item)} px={2} iconOnly><Icon >delete</Icon></MKButton>
  
    </Stack>


      setPasskeyRenderData(prevItems => { 
        return [...prevItems, item];
      });

  }

  const toggleEditKeyModal = (key) => {
    setKey(key)
    toggleKeyModal('showEditKey')

    setInputData({
      ...inputData,
      ["name"]: key.name,
      ["platform"] : key.platform
    })

  }

  const toggleDeleteKeyModal = (key) => {
    setKey(key)
    toggleKeyModal('showDeleteKey')


  }

 


  const toggleKeyModal = (type) => {   
    if(type === "all"){
      setModalState({  
        showInviteAddKey:false, 
        showAddKey:false,
        showEditKey:false,
        showDeleteKey:false,
        verifyAccount:true})   
    }
    else setModalState({ ...modalstate, [type]:!modalstate[type] })   
  }


  const makeRecord = (item) => { 
        
    item.lastUsed = formatDate(item.lastUsed) 
    item.createdAt = formatDate(item.createdAt) 
    item.updatedAt = formatDate(item.updatedAt) 
    item.hide = false 
    return item
      
  }


  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric"}
    return new Date(dateString).toLocaleDateString(undefined, options)
  }


  const verifyAccountHandler = async() => { 
    
    try { 
      /** Start confirm user passkey before add new passkey */ 
      let result = await verify(loggedInUser.email, false); 
      if (result.error) { 
        setError(result.error.message)
        return
      }

      let asseResp = await startAuthentication(result);
      asseResp.email = loggedInUser.email;
       

      asseResp.passKeyOption = modalstate.showDeleteKey ? "delete" : "create"

      let authn = await verifyComplete(asseResp);
      if (authn.error) {
        setError(authn.error.message)
        toggleKeyModal("all")
        return
      }

      if(modalstate.showDeleteKey){
        let result = await removePasskey(key.id)
        toggleKeyModal("all")
        if (result.error) {
          setError(result.error.message) 
          return
        }

        setPasskeyData(result.authenticators);
      } 
      else toggleKeyModal("verifyAccount") 

     
    } catch (error) {
      setError(error.message)
      toggleKeyModal("all")
      
    }
   
  }

  const requestInvitePasskey = async () => {
    try { 
      let result = await inviteAddPasskey(); 
      
      if(!result) return;

      if (result.error){
        setError(result.error.message)
        setModalState({ ...modalstate, ["showInviteAddKey"]:false, ["verifyAccount"]: true })  
        return
      } 
      else {
        setInfoMessage("Please check your email for adding passkey verification token.")
      }

    } catch (error) {
      console.log("showInviteAddKey error ", error)
      setError(error.message)  
    }
    finally{
      setModalState({ ...modalstate, ["showInviteAddKey"]:false, ["verifyAccount"]: true })   
    }
  }

  const addNewPasskey =  async() => {
    /**  Start add new passkey */ 
    try { 

      if (inputData.name === "" || inputData.name === undefined) {
        return
      }
      
      let result = await addPasskey(loggedInUser.email); 
      if (result.error){
        setError(result.error.message)
        setModalState({ ...modalstate, ["showAddKey"]:false, ["verifyAccount"]: true })  
        return
      } 
      let attResp = await startRegistration(result);

      console.log("startRegistration attResp =  ", attResp)

      attResp.email = loggedInUser.email;
     
      attResp.keyName = inputData.name

      let user = await addPasskeyComplete(attResp);
      if (user.error) {
        setError(user.error.message)  
      }
      else { 

        setPasskeyData([])

        console.log("addPasskeyComplete user.authenticators length = ", user.authenticators)

        if (!user.authenticators) return;

        user.authenticators.map(auth => {
          setPasskeyData(prevItems => { 
            return [...prevItems, auth];
          }); 
        })  
        

      }  

    } catch (error) {
      console.log("addNewPasskey error ", error)
      setError(error.message)  
    }
    finally{
      setModalState({ ...modalstate, ["showAddKey"]:false, ["verifyAccount"]: true })   
    }
     
  }


  const onUpdateKeyHandler = async () => {
   

    let keyData = key;
    keyData.name = inputData.name
    keyData.platform = inputData.platform

    console.log("onUpdateKeyHandler keyData ", keyData  )

    let result = await updatePasskey(keyData)

   

    if(result.error) setError(result.error.message)
    else {
      console.log("onUpdateKeyHandler result ", result  )
      setPasskeyRenderData(passkeyRenderData.map(item => {
        if (item.id === keyData.id) return {...item, ["name"]: keyData.name, ["platform"]: keyData.platform}
        else return item
      }))

      toggleKeyModal("showEditKey")
    }

    //setModalState({ ...modalstate, ["showEditKey"]:false })   

  }
  

  const onChangeInputValue = (evt) => {
    setInputData({
      ...inputData,
      [evt.target.name]: evt.target.value
    })
  }

  return (
    <> 
      <AppLayout>
       
      <MKBox component="section"  >
      
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
        
            <MKBox component="form" p={2} >

              {loggedInUser && 
                <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                  <MKTypography variant="h2" mb={1}>
                    Hello {loggedInUser.firstName}
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={2}>
                    Update your passkey here
                  </MKTypography>
                </MKBox>
              }

              {error && 
                <MKAlert color="error">
                  Whoop!&nbsp; {error}
                </MKAlert>
              }

              {infoMessage && 
                <MKAlert color="info">
                  {infoMessage}
                </MKAlert>
              }
              
            
              <Stack justifyContent={"right"} direction="row" spacing={2}>
                <MKButton variant="gradient" color="primary" onClick={() =>  {setModalState({ ...modalstate, ["verifyAccount"]:true }); toggleKeyModal("showAddKey") } }>
                    add passkey
                </MKButton>
              
                  <MKButton variant="gradient" color="primary" onClick={() =>  {setModalState({ ...modalstate, ["verifyAccount"]:true }); toggleKeyModal("showInviteAddKey") } }>
                      invite passkey
                  </MKButton>
              </Stack>
            

              
                {passkeyRenderData &&  
                <Table
                  columns={[
                      { name: "number", align: "left" },
                      { name: "name", align: "center" },
                      { name: "platform", align: "center" },
                      { name: "lastUsed", align: "center" },
                      { name: "createdAt", align: "center" }, 
                      { name: "control", align: "center" },
                  ]}
                  rows={passkeyRenderData}
                  /> 
                }
              
            </MKBox>
              
          </MKBox> 
      </MKBox> 


      <Modal open={modalstate.showInviteAddKey} onClose={() => toggleKeyModal("showInviteAddKey")} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={modalstate.showInviteAddKey} timeout={300}>
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
        <Card>
              <MKBox
              variant="contained"
              bgColor="primary"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center" 
              >
                  
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>Invite Add Passkey</MKTypography>
                  
              
              </MKBox>
          
              <MKBox pb={3} px={3}>
                  
              {modalstate.verifyAccount === false ?  
                <MKBox>
                  <MKBox  alignitems="center" py={2}>
                      <MKTypography variant="body2" color="black">Please click submit to request add new passkey. You will get an email for passkey veification token.</MKTypography>
                      <MKTypography variant="body2" color="black">Please logout/login again then follow a prompt to add new passkey for this account.</MKTypography>
                       
                  </MKBox> 

                  <MKBox> 
                   <Divider sx={{ my: 3 }} />
                   <MKTypography variant="body2" color="info">The token will expire in 15 minutes after you submit.</MKTypography>
                  </MKBox>


                   <MKBox display="flex" justifyContent="space-between" p={1.5}>
                      <MKButton variant="gradient" color="dark" onClick={() => toggleKeyModal("showInviteAddKey")}>
                      cancel
                      </MKButton>
                      <MKButton variant="gradient" color="primary" onClick={requestInvitePasskey}>
                      Submit
                      </MKButton>
                  </MKBox> 
                </MKBox>
                :  <MKBox> 


                    <Divider sx={{ my: 3 }} />
                    <MKTypography variant="body2" color="text">Please verify your account before add new Passkey</MKTypography>

                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="dark" onClick={() => toggleKeyModal("showInviteAddKey")}>
                          cancel
                        </MKButton>
                        <MKButton variant="gradient" color="primary" onClick={verifyAccountHandler}>
                          verify
                        </MKButton>
                    </MKBox> 

                   </MKBox>
                  }
                 
                  
              </MKBox>
              
              </Card>
          </Grid>
        </Slide>
      </Modal>





      <Modal open={modalstate.showAddKey} onClose={() => toggleKeyModal("showAddKey")} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={modalstate.showAddKey} timeout={300}>
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
        <Card>
              <MKBox
              variant="contained"
              bgColor="primary"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center" 
              >
                  
              <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>Add Passkey</MKTypography>
                  
              
              </MKBox>
          
              <MKBox pb={3} px={3}>
                  
              {modalstate.verifyAccount === false ?  
                <MKBox>
                  <MKBox display="flex" alignitems="center" justifyContent="space-between" py={2}>
                      <MKTypography variant="body2" color="text">Enter your Passkey information</MKTypography>
                  </MKBox>
                  
                  <MKBox mb={2}>
                    <MKInput type="text" name='name' label="Key Name" fullWidth  required onChange={onChangeInputValue}/>
                  </MKBox>  

                  <MKBox> 
                   <Divider sx={{ my: 3 }} />
                   </MKBox>


                   <MKBox display="flex" justifyContent="space-between" p={1.5}>
                      <MKButton variant="gradient" color="dark" onClick={() => toggleKeyModal("showAddKey")}>
                      cancel
                      </MKButton>
                      <MKButton variant="gradient" color="primary" onClick={addNewPasskey}>
                      Submit
                      </MKButton>
                  </MKBox> 
                </MKBox>
                :  <MKBox> 


                    <Divider sx={{ my: 3 }} />
                    <MKTypography variant="body2" color="text">Please verify your account before add new Passkey</MKTypography>

                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="dark" onClick={() => toggleKeyModal("showAddKey")}>
                          cancel
                        </MKButton>
                        <MKButton variant="gradient" color="primary" onClick={verifyAccountHandler}>
                          verify
                        </MKButton>
                    </MKBox> 

                   </MKBox>
                  }
                 
                  
              </MKBox>
              
              </Card>
          </Grid>
        </Slide>
      </Modal>


      <Modal open={modalstate.showEditKey} onClose={() => toggleKeyModal("showEditKey")} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={modalstate.showEditKey} timeout={300}>
        <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">
        <Card>
              <MKBox
              variant="contained"
              bgColor="primary"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center" 
              >
                  <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    Update Passkey
                  </MKTypography>
              
              </MKBox>
          
              <MKBox pb={3} px={3}>
                  <MKBox display="flex" alignitems="center" justifyContent="space-between" p={2}>
                      <MKTypography variant="h5">Update Passkey '{key.name}'</MKTypography>  
                  </MKBox>
                  
                  <MKBox display="flex" alignitems="center" justifyContent="space-between" py={2}>
                      <MKTypography variant="body2" color="text">Enter your key information</MKTypography>
                  </MKBox>
                  
                  <MKBox mb={2}>
                    <MKInput type="text" name='name' label="Key Name" fullWidth defaultValue={key.name} required onChange={onChangeInputValue}/>
                  </MKBox> 

                  <MKBox mb={2}>
                    <MKInput type="text" name='platform' label="Platform" fullWidth  defaultValue={key.platform} required onChange={onChangeInputValue} />
                  </MKBox>

                  <MKBox display="flex" justifyContent="space-between" p={1.5}>
                    <MKButton type="submit" variant="contained" color="primary"  onClick={onUpdateKeyHandler}>
                      update
                    </MKButton>

                    <MKButton variant="gradient" color="dark" onClick={() => toggleKeyModal("showEditKey")}>
                      cancel
                    </MKButton>
                  </MKBox>
              </MKBox>
              
              </Card>
          </Grid>
        </Slide>
      </Modal>


      <Modal open={modalstate.showDeleteKey} onClose={() => toggleKeyModal("showDeleteKey")}  sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={modalstate.showDeleteKey} timeout={300}>
            <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">
                <MKBox
                position="relative"
                sx={{ maxWidth: 'md' }}
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
                >
                <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                    <MKTypography variant="h4">Are you sure to remove this Passkey?</MKTypography> 
                </MKBox>

                <Divider sx={{ my: 0 }} />
                <MKBox p={3}>
                  <MKTypography variant="h5">Key Name: {key.name}, Platform: {key.platform}</MKTypography>
                  <MKTypography variant="body">This transaction cannot be undone. You also need to remove the Passkey from your device manually.</MKTypography>
                  <Divider sx={{ my: 0 }} />
                  {key.platform && key.platform.indexOf("Apple") >=0 && <MKTypography variant="body"> On your iPhone/Mac go to Passwords App -> Select the passkey and remove.</MKTypography>}
                </MKBox>

                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                    <MKButton variant="gradient" color="dark" onClick={() => toggleKeyModal("showDeleteKey")}>
                    cancel
                    </MKButton>
                    <MKButton variant="gradient" color="error" onClick={verifyAccountHandler}>
                    remove
                    </MKButton>
                </MKBox>
                </MKBox>
            </Grid>
          </Slide>
      </Modal>
      
 
      
    </AppLayout>
  </>

  );


}

export default Passkey;
