/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef, useReducer, act } from "react";
import { useParams } from 'react-router-dom';
// @mui material components
import Breadcrumbs from "components/Breadcrumbs";
 


 
// Material Kit 2 PRO React components
 
import MKBox from "components/MKBox";  
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom"
import AppLayout from "components/Layouts/AppLayout"; 
import MKAlert from "components/MKAlert";
import { appAuth } from "../contexts/AppContext" 
import AppMenu from "components/AppKey/AppMenu"; 
import EmailTemplate from "components/AppKey/EmailTemplate"; 
import AppLogs from "components/AppKey/AppLogs";
import AppSetting from "components/AppKey/AppSetting";
import AppOption from "components/AppKey/AppOption";
import AppUser from "components/AppKey/AppUser";
import AppKey from "components/AppKey/AppKey"; 



export default function AppDetail() {  

    
    const [successMessage, setSuccessMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [appState, setAppState] = useState({activeTab:-1}) 
   
    const navigate = useNavigate() 
    const { currentApp,  getApp, requestError, requestSuccess, setRequestError, setRequestSuccess} = appAuth() 
    const [appToEdit, setAppToEdit] = useState(currentApp) 
    let { appId } = useParams(); 

    const handleChangeAppMenu = (item) => {

        console.log("handleChangeAppMenu ", item) 
        setAppState({...appState, ["activeTab"]: item.index})
      
    };  
    const renderRef = useRef(false) 


    useEffect(() => { 

        if(requestError) showError(requestError.message)
        else if(requestSuccess) showSuccess(requestSuccess.message)
        
        console.log("useEffect requestSuccess ", requestSuccess)
    }, [requestError, requestSuccess])

    useEffect(() => {  
        if(currentApp) setAppToEdit(currentApp) 
    }, [currentApp])

    function showError(message){
        setErrorMessage(message);

        setTimeout(() => {
            setErrorMessage(); 
        }, 5000);
        
        setRequestError(null)
    }

    function showSuccess(message){
        setSuccessMessage(message);

        setTimeout(() => {
            setSuccessMessage(); 
        }, 5000);
        
        setRequestSuccess(null)
    }

    
    useEffect(() => { 
        console.log(" AppDetail start render . ")

        const loggedInUser = JSON.parse(localStorage.getItem("appkey.io.user"));  
        if (!loggedInUser || !loggedInUser["access-token"]) navigate("/signin")

        if (renderRef.current === false){

            async function fetchApp() {
                
                let app = await getApp(appId);
               

                if(app === undefined){
                    navigate("/apps")
                    return
                } 

                setAppToEdit(app);
                    
                console.log("AppDetail appToEdit ", appToEdit)  
                setAppState({...appState, ["activeTab"]: 0})
            }

            fetchApp()

            return () => {
                renderRef.current = true
                console.log("AppDetail render clean up. ")
            }
        }

       

    }, []) 
 

    return (
    <> {currentApp !== undefined  &&  currentApp !== null && currentApp.name && 
    <AppLayout>
       
       <MKBox component="section" > 
            <Breadcrumbs pb={2} 
                routes={[
                    { label: "Apps", route: "/apps" },
                    { label: currentApp.name},
                        
                ]}
            />   

            <AppMenu clickItemHandler={handleChangeAppMenu} items={[{icon:"settings", label:"Settings", active:true, index:0}, {icon:"tune", label:"Options", index:1}, {icon:"manage_accounts", label:"Users", index:2}, {icon:"key", label:"Keys", index:3}, {icon:"forward_to_inbox", label:"Templates", index:4}, {icon:"query_stats", label:"Logs", index:5}]}/>
  
            <Card sx={{ p: 1,   mb: 4, boxShadow: ({ boxShadows: { xxl } }) => xxl,}} >
                
            <MKBox component="section" >   
                {errorMessage  && <MKAlert color="error" dismissible>Whoop! {errorMessage}</MKAlert>}
 
                {successMessage  && <MKAlert color="info" dismissible>Done! {successMessage}</MKAlert>}

                { appState.activeTab === 0  && <MKBox
                                        role="tabpanel" 
                                        id={`app-tabpanel-${appState.activeTab}`}
                                        aria-labelledby={`app-tab-${appState.activeTab}`}> 
                    <AppSetting app={appToEdit}/> 

                </MKBox> }
                
                { appState.activeTab === 1 && <MKBox
                                        role="tabpanel" 
                                        id={`app-tabpanel-${appState.activeTab}`}
                                        aria-labelledby={`app-tab-${appState.activeTab}`} > 
                   
                    <AppOption app={appToEdit} />

                     
                </MKBox>}

                { appState.activeTab === 2 && <MKBox
                                        role="tabpanel" 
                                        id={`app-tabpanel-${appState.activeTab}`}
                                        aria-labelledby={`app-tab-${appState.activeTab}`} > 

                    <AppUser app={appToEdit} />
                
                </MKBox> }
                
                { appState.activeTab === 3 && <MKBox
                                        role="tabpanel" 
                                        id={`app-tabpanel-${appState.activeTab}`}
                                        aria-labelledby={`app-tab-${appState.activeTab}`} > 
                    
                    <AppKey app={appToEdit} />
                         

                </MKBox> }

                { appState.activeTab === 4  && <MKBox
                                        role="tabpanel" 
                                        id={`app-tabpanel-${appState.activeTab}`}
                                        aria-labelledby={`app-tab-${appState.activeTab}`} > 
                    
                    <EmailTemplate app = {appToEdit}  /> 
                </MKBox> }

                { appState.activeTab === 5 && <MKBox
                                        role="tabpanel" 
                                        id={`app-tabpanel-${appState.activeTab}`}
                                        aria-labelledby={`app-tab-${appState.activeTab}`} > 
                    <AppLogs appId={appToEdit.appId}/>
                </MKBox>
                }

                
            </MKBox>
        </Card>
        </MKBox> 

        
    </AppLayout>
    }
    </>

    );

 
  


}

