/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React example components
import DefaultNavbar from "components/DefaultNavbar";
import Footer from "components/Footer";

// Material kit 2 PRO React page layout routes
import routes from "routes";
import {  useNavigate } from "react-router-dom"
import { useState, useEffect } from "react";

function CoverLayout({ coverHeight, image, children }) {

  const navigate = useNavigate() 
  const loggedInUser = JSON.parse(localStorage.getItem("appkey.io.user"));  
 

  useEffect(() => { 
      if (loggedInUser && loggedInUser['access-token']){

        // navigate("/apps")

      } 
  }, [])


  return (
    <MKBox height="calc(100vh - 1rem)">
      <MKBox width="100%" position="absolute" top="0.25rem">
        <DefaultNavbar
          brand = "Pass Clef"
          routes={routes.route}  
          transparent
          dark
        />
      </MKBox>
      
      <MKBox
        width="calc(100% - 2rem)"
        minHeight={coverHeight}
        borderRadius="xl"
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox mt={{ xs: -8, lg: -8 }} px={1} width="calc(100% - 2rem)" mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MKBox>
     
      <MKBox width="100%" pt={12}>
          <Footer />
      </MKBox>
      
      
    </MKBox>

    
  );
}
 

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  coverHeight: PropTypes.string, 
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
