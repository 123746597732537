/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox"; 
import MKTypography from "components/MKTypography"; 
import MKButton from "components/MKButton"; 
import MKInput from "components/MKInput"; 
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import MKAvatar from "components/MKAvatar"; 
import CloseIcon from "@mui/icons-material/Close";
import Stack from '@mui/material/Stack';
import Icon from "@mui/material/Icon";
import { Link, useNavigate } from "react-router-dom"
import ProfileLayout from "components/Layouts/ProfileLayout";
import defaultAvatar from 'assets/images/default-avatar.png' ;
import { useAuth } from "../contexts/AuthContext"
import countryList from "components/AppKey/countryList.json"
import CustomSelection from "components/AppKey/CustomSelection";
import {startAuthentication} from '@simplewebauthn/browser';

function Profile() {
  const navigate = useNavigate()  
  const [profile, setProfile] = useState({})
  const [currentSubscribe, setCurrentSubscribe] = useState()
  const [errorMessage, setErrorMessage] = useState("")
  const [plans, setPlans] = useState([])
  const [account, setAccountPayment] = useState()
  const [avatar, setAvatar] = useState({})
  const [address, setAddress] = useState({})
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [billingHistory, setBillingHistory] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])
  let location = useLocation()

  const [selectedPlan, setSelectedPlan] = useState({})
  const {verify, verifyComplete, updateAutoRenewSubscription, getInvoice, getBillingHistory, getPlans, logout, getSubscriptionsAccount, addPaymentMethod, subscribe, updateProfile, updateUserCache, uploadAvatar, updateAddress, config} = useAuth() 
  const renderRef = useRef(false) 
  const fileInput = useRef(null)
 
  const [braintreeInstance, setBraintreeInstance] = useState({})
  const [modalState, setModalState] = useState({showPlan:false, showAddCard:false, showBillingAddress:false, showConfirmSubscribe:false } )
  const toggleModalPlan = () =>  setModalState({...modalState, ["showPlan"]: !modalState.showPlan}) 

  const toggleModal = (type, close) => {
    if(type == "clear") setModalState({}) 
    else if (close) {
      setModalState({...modalState, 
        [type]: !modalState[type],
        [close]: !modalState[close]
      }) 
    }
    else setModalState({...modalState, [type]: !modalState[type]}) 

    
  }  

  useEffect(() => { 

    async function fetchPlans() {
                
      let result = await  getPlans() 

      if (result.error && result.error.code === 405){
        logout()
        navigate("/signin")
        return;
      }

      setPlans(result); 
      console.log("Profile plans ", result)  
      
      await getUserSubscriptionsAccount()


      console.log("Profile location ", location)  

      if(location.search.indexOf("showPlan=true") >= 0) toggleModalPlan()

    }

    if (renderRef.current === false) { 

      const catche = localStorage.getItem("appkey.io.user");
      let loggedInUser = JSON.parse(catche); 

      console.log("Profile catche ", catche)  

      if (!loggedInUser || !loggedInUser['access-token'] ) {
        
        navigate("/signin")
        return;
      }

      setProfile(loggedInUser)
      if(loggedInUser.avatar) setAvatar(loggedInUser.avatar)

      fetchPlans()

      
      return () => {
        renderRef.current = true
        console.log("Profile render clean up. ")
      }

    }

  }, [])


  const showBillingHistory = async () => {
    toggleModal("showHistory")
    let result = await getBillingHistory() 
    if (!result.error) setBillingHistory(result)
  }

  const onClickPaymentMethod = async () => {
    if(address && address.id) toggleModal("showPayment")
    else toggleModal("showBillingAddress")
  }


  const getUserSubscriptionsAccount = async() => {
     
    let result = await getSubscriptionsAccount()
    if (result.error){
      return
    }
    setAccountPayment({
      ...account,
      ['paymentMethods']:result.customer.paymentMethods,
      ['addresses']: result.customer.addresses
    })

    setPaymentMethods(result.customer.paymentMethods)

    if(result.customer.addresses && result.customer.addresses[0]){
      setAddress(result.customer.addresses[0])
    }
    
    console.log(" getUserSubscriptionsAccount result.plans ", result.plans[0])
    if(result.plans[0]){
      setCurrentSubscribe(result.plans[0])
    }  

    return result
  }


  const handleAvatarClick = () => {
    if (!avatar.loading ) fileInput.current.click()
  }



  const selectPlan = async (plan) => { 

    console.log("Profile selectPlan  ", plan)
    if (currentSubscribe.developerPlanId === plan.developerPlanId) return;

    setSelectedPlan(plan) 
    toggleModal("showConfirmSubscribe") 

    console.log("Profile currentSubscribe  ", currentSubscribe)
    
  }

  const subscribePlan = async (plan, card) =>{

    let checkPoint = await verifyPasskey()
    if(!checkPoint) return;
    
    toggleModal("clear")

    console.log("subscribePlan paymentMethods ", paymentMethods)
    card = card ? card : paymentMethods[0]

    console.log("subscribePlan card ", card)

    if(card) { 
      let payToken = card.token;  
      let result = await subscribe(plan, payToken)
      if(!result.error){
        setCurrentSubscribe(result) 
        getUserSubscriptionsAccount()
      }  
      
    }
  }

  const updateProfileHandler = async() => {

   
    console.log("updateProfileHandler avatar ", avatar)

    await updateProfile(profile);

    if (avatar.file) {
      setAvatar({
        ...avatar,
        ['loading'] : true
      })

      let result = await uploadAvatar(avatar.file)

      if(result && result.urlOrigin) setAvatar(result);
      else {

      }
      console.log("updateProfileHandler result ", result)

    }
  }


  const onChangeValue = async (evt) => { 
    setProfile({
        ...profile,
        [evt.target.name]: evt.target.value
    })
  } 

  const onChangeAddressValue = async (evt) => { 
    setAddress({
        ...address,
        [evt.target.name]: evt.target.value
    })
  } 

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      console.log("onImageChange image ", img)
      setAvatar({
        ...avatar,
        ['file']: img,
        ['urlOrigin'] : URL.createObjectURL(img)
      })

      
    }
  };


  const onSelectCountry = (item) => { 

    setAddress({
      ...address,
      ["countryCodeAlpha2"]: item.code
  })
}

  const saveAddress = async() => {
    let result = await updateAddress(address)

    if(result.error) return 

    if (selectedPlan){
      toggleModal("showAddCard", "showBillingAddress")
      addPayment()
    } 
    else toggleModal("showBillingAddress")

   
  }

  const confirmSubscribe = () => { 
     
    if(paymentMethods.length == 0){

      if (address && address.id){
        toggleModal("showAddCard", "showConfirmSubscribe")
        addPayment()
      } 
      else toggleModal("showBillingAddress", "showConfirmSubscribe")
    
    } 
    else {
      toggleModal("clear")
      subscribePlan(selectedPlan) 
    } 
    
   
  }

  const verifyPasskey = async () => {

    let challenge = await verify();
    if(challenge.error) {
      return;
    }

    let asseResp = await startAuthentication(challenge); 
    let authn = await verifyComplete(asseResp);
    if (authn.error) {
      return false
    }

    return true;
  }

  const savePayment = async () => {
     
    if(!braintreeInstance) return;

    let checkPoint = await verifyPasskey()
    if(!checkPoint) return;

    setLoadingPayment(true);

    braintreeInstance.requestPaymentMethod( async function (requestPaymentMethodErr, payload) {
        
        
        if(requestPaymentMethodErr){
            setLoadingPayment(false);
            return;
        } 

        braintreeInstance.teardown(function (teardownErr) { }); 

        let result = await addPaymentMethod(payload)
        console.log("addPaymentMethod result ", result) 
       

        if(result.success){ 

          toggleModal("showAddCard")

          await getUserSubscriptionsAccount();

          if(selectedPlan) subscribePlan(selectedPlan, result.paymentMethod); 
        }  

        setLoadingPayment(false); 
    });
}

  const addPayment = () => {
    setLoadingPayment(true) 

    setTimeout(function() {  // set timeout to wait for div dropin-container is fully created 
        //let tokenizationKeys = "sandbox_d5j3zcpf_f3gwz3xzcfy37xnz";
      
        braintree.dropin.create({
            vaultManager: true,
            authorization: config.tokenizationKeys, // get from  Braintree Control Panel > API > Generate New Tokenization Key
            container: '#dropin-container',
            card: { 
                makeDefault:true,
                //verifyCard:true,
                cardholderName: {
                    required: true
                } 
            }
        }, function (createErr, instance) {  
            console.log('dropin.create createErr ', createErr);
            setLoadingPayment(false);

            if(instance) setBraintreeInstance(instance)
            else setErrorMessage("Whoop! Invalid Payment Data") 
        });
    }, 1000);
}


  const formatDate = (dateString) => {
    const options = { year: "2-digit", month: "2-digit", day: "2-digit", hour: 'numeric', hour12: true}
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const downloadInvioce = async (invoice) => {
    const response = await getInvoice(invoice)

    if(response.error) return

    const pdfBlob = await response.blob() 
    const url = window.URL.createObjectURL(pdfBlob); 

    const tempLink = document.createElement("a")
    tempLink.href = url
    tempLink.setAttribute("download", `appkey_billing_${invoice.invoiceId}`)
    document.body.appendChild(tempLink);
    tempLink.click();

    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url)

    
  }

  const renderInvioce = () => billingHistory.map(invoice => (
    <MKBox   
      key={invoice.invoiceId}
      width="90%"
      height="6rem"  
      coloredShadow="dark"
      display="flex"
      alignItems="center"
      justifyContent="space-between" 
      borderRadius="xl"
      p={2}> 

      <MKBox textAlign="left">
        <MKTypography variant="body" color="text" > {invoice.title} </MKTypography> 
        <MKTypography variant="body2" color="text" > {invoice.amount} - {formatDate (invoice.createdAt)}</MKTypography> 
      </MKBox> 
      <MKButton variant="outlined" color="info"  px={2} iconOnly onClick={() => downloadInvioce(invoice)}> <Icon>download</Icon> </MKButton>
         
      
    </MKBox>
  ))


  const renderPlans = () => plans && plans.map( plan => ( 
    <MKBox   
      key = {plan.developerPlanId}
      width="90%"
      height="7rem"  
      coloredShadow= { currentSubscribe && currentSubscribe.developerPlanId === plan.developerPlanId ? "info" : "dark"}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ cursor: "pointer" }}
      borderRadius="xl"
      p={3} 
      onClick={() => selectPlan(plan)}
      > 
    <Stack textAlign="left">
      <MKTypography variant="body" color="text" > {plan.name} - {plan.currency} ${plan.price}</MKTypography> 
      <MKTypography variant="body2" color="text" > {plan.desc}</MKTypography> 
      <MKTypography variant="body2" color="text" > {plan.durationText }</MKTypography> 
    </Stack>

   
     
      {currentSubscribe && currentSubscribe.developerPlanId === plan.developerPlanId ? <MKTypography variant="body2" color="info" >Your Current Plan</MKTypography>  :  <Icon color="primary" fontSize= "large" >arrow_right</Icon>  }
     
    </MKBox>
  ))

  const updateAutoRenew = (value) => {

    updateAutoRenewSubscription(currentSubscribe, value)

    setCurrentSubscribe({
      ...currentSubscribe,
      ['autoRenew']: value
    }) 
  }
 

  const changeSubscriptionPLan = () => {
    toggleModalPlan()
  }

  const renderPaymentMethods = () => paymentMethods.map( item => ( 
    <MKBox   
      key = {item.last4}
      width="90%"
      height="7rem"  
      coloredShadow="dark"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ cursor: "pointer" }}
      borderRadius="xl"
      p={3} 
      //onClick={() => selectPlan(plan)}
      > 
    <Stack textAlign="left">
      <MKTypography variant="body" color="text" > {item.cardType} - {item.last4} </MKTypography> 
      { !item.expired  && <MKTypography variant="body2" color="text" > Expired Date: {item.expirationDate} </MKTypography> }
      { item.expired  && <MKTypography variant="body2" color="error" > Expired Date: {item.expirationDate} (Expired) </MKTypography> }
      {item.expired && <MKTypography variant="body2" color="error" > Your your payment method is expired. Please update your payment. </MKTypography> }
    </Stack>
     
    <MKButton variant="outlined" color="primary"  iconOnly onClick={() =>  { toggleModal("showAddCard", "showPayment");  addPayment()} }> <Icon>edit</Icon> </MKButton>

    </MKBox>
  ))



  return (
    <> 
  <ProfileLayout>
       
    <MKBox component="section"  >
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container >
              <Grid item xs={12} lg={5} position="relative" px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.3),
                      rgba(gradients.dark.state, 0.6)
                    )}`,
                  backgroundSize: "cover",
                }}
                 textAlign="center"
                 justifyContent="center"
              >
               
                  <MKBox pt={4} display="inline-block" onClick={handleAvatarClick}   sx={{ cursor: "pointer" }}>  
                 
                    <MKAvatar src={avatar && avatar.urlOrigin ? avatar.urlOrigin : defaultAvatar} alt="Avatar" size="xxl" /> 

                    {avatar.loading ? <MKBox px={4} ><div className="dotloader" ></div> </MKBox> :
                     <MKBox mt={-5} mr={-5}>
                      <MKButton variant="text" color="info"   iconOnly> <Icon>edit</Icon> </MKButton>
                    </MKBox>
                    }
                   
                    <input type="file" id="wizard-picture" accept="image/png, image/jpeg"  hidden ref={fileInput} onChange={onImageChange}/>
                   
                  </MKBox>
                 

                  <MKBox py={2} mx="auto"  my="auto" justifyContent="center">  

                    <MKTypography variant="body" color="white" mb={1}>
                      {profile.email}
                    </MKTypography>
                      
                    <MKBox pt={0.5} pb={3} px={3}>
                      <MKBox display="flex" py={2}>
                        <MKInput
                          py={3}
                          variant="standard"
                          color="primary"
                          placeholder="First Name" 
                          value={profile.firstName}
                          InputLabelProps={{ shrink: true, style: { color: "white" }  }}
                          inputProps={{ style: { color: "white" } }}
                          fullWidth
                          name="firstName"
                          onChange={onChangeValue}
                        />
                       </MKBox>
                      <MKBox display="flex" >
                        <MKInput 
                            variant="standard"
                            color="primary" 
                            placeholder="Last Name" 
                            name="lastName"
                            value={profile.lastName}
                            InputLabelProps={{ shrink: true, style: { color: "white" }  }}
                            inputProps={{ style: { color: "white" } }}
                            fullWidth
                            onChange={onChangeValue}
                          />
                        
                      </MKBox>

                      <MKBox display="flex" py={2}>
                        <MKInput 
                            variant="standard"
                            color="primary" 
                            placeholder="Company Name" 
                            name="company"
                            value={profile.company}
                            InputLabelProps={{ shrink: true, style: { color: "white" }  }}
                            inputProps={{ style: { color: "white" } }}
                            fullWidth
                            onChange={onChangeValue}
                          />
                        
                      </MKBox>
                   
                      <MKBox display="flex" justifyContent="space-between" py={3}>
                        <MKButton variant="gradient" color="primary" onClick={updateProfileHandler}>
                          update
                        </MKButton> 
                      </MKBox>

                    </MKBox>
                  </MKBox>
                
              </Grid>

              <Grid  xs={12} lg={7}   >  
                <MKBox width="100%"  p={2} textAlign="center"  justifyContent="center">

                  {currentSubscribe ?
                  <MKBox   
                  
                    minHeight="9rem"  
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"  
                    mx="auto"
                    borderRadius="xl" 
                    
                    mt={2}
                    > 

                    {currentSubscribe.developerPlanId == "free-plan" ?  
                      <MKBox width="100%"   textAlign="center"  justifyContent="center" p={2}>
                        <MKTypography variant="body1" color="text" mb={2}>
                          You are on a Hobbyist free plan account
                        </MKTypography>
                        <MKTypography variant="body1" color="text" mb={2}>
                        Upgrade your account to a paid developer account.
                        </MKTypography>

                        <MKButton variant="gradient" color="primary" onClick={() => toggleModal("showPlan")}>
                          upgrade now
                        </MKButton>

                      </MKBox>
                    :
                      <Stack textAlign="center" p={2} >
                        <MKTypography variant="body" color="text" > Your subscription is  {currentSubscribe.developerPlanId} - {currentSubscribe.plan.currency} ${currentSubscribe.plan.price}</MKTypography> 
                        {currentSubscribe.autoRenew && <MKTypography variant="body2" color="text" > Your subscription will be renewed at: {currentSubscribe.nextBillingDate}</MKTypography> }
                        {!currentSubscribe.autoRenew && <MKTypography variant="body2" color="text" > Your subscription will be ended at:  {currentSubscribe.nextBillingDate}</MKTypography> }

                        <MKBox display="flex" alignItems="center" justifyContent="space-between">
                          <MKBox>
                            <Switch checked={currentSubscribe.autoRenew} onChange={() => { updateAutoRenew(!currentSubscribe.autoRenew) }} />
                            
                            <MKTypography variant="button" color="text" fontWeight="regular" >
                              Auto Renew: {currentSubscribe.autoRenew ? "ON" : "OFF"}
                            </MKTypography>
                          </MKBox>

                          <MKButton variant="text" color="info"  size="large"  onClick={changeSubscriptionPLan} >
                            <MKTypography variant="button" color="info" fontWeight="regular"   >
                              Change Plan
                            </MKTypography>
                          </MKButton>

                         
                        </MKBox> 

                      </Stack> 
                    } 
                    </MKBox>

                    : 
                      <MKTypography variant="button" color="info" fontWeight="regular"> Loading Plan...</MKTypography>     

                    }


                    <MKBox width="90%"  
                      alignItems="center"  
                      mx="auto"
                      borderRadius="xl" 
                      px={2} pt={2} >  

                      <Grid container>
                        <Grid  xs={12} lg={4} >  
                        <MKBox mx="auto"  textAlign="center" px={3} onClick={() => toggleModal("showBillingAddress")}  sx={{ cursor: "pointer" }}>
                          <MKButton variant="text" color="info"  size="large" 
                            iconOnly><Icon>location_on</Icon>
                          </MKButton>
                          <MKTypography variant="body1" color="text" mb={2}> Billing Address </MKTypography>
                        </MKBox>
                      
                        </Grid>

                        <Grid  xs={12} lg={4} >  
                        <MKBox  mx="auto" textAlign="center" px={3}  sx={{ cursor: "pointer" }} onClick={onClickPaymentMethod}>
                          <MKButton variant="text" color="info"  size="large" 
                            iconOnly><Icon>credit_card</Icon>
                          </MKButton>
                          <MKTypography variant="body1" color="text" mb={2}> Payment Method </MKTypography>
                        </MKBox>
                        </Grid>

                        <Grid  xs={12} lg={4} >  
                        <MKBox  mx="auto" textAlign="center" px={3} sx={{ cursor: "pointer" }} onClick={showBillingHistory}>
                          <MKButton variant="text" color="info"  size="large" 
                            iconOnly><Icon>history</Icon>
                          </MKButton>
                          <MKTypography variant="body1" color="text" mb={2}> Billing History </MKTypography>
                        </MKBox>
                        </Grid>
                      </Grid> 

                      <MKBox  alignItems="center" pt={3}>
                        <MKTypography variant="body2"> To cancel your subscription email us at info@cosync.io</MKTypography> 
                      </MKBox> 


                    </MKBox> 
                </MKBox>
             
               
                
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>



      <Modal open={modalState.showHistory} onClose={() => toggleModal("showHistory")} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={modalState.showHistory} timeout={300}>
        <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">
            <MKBox 
              sx={{ width: '95%' }}
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              borderWidth= {2}
              bgColor="white"
              shadow="xl"
              textAlign="center"
            >


            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Billing History</MKTypography> 
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={ () => toggleModal("showHistory")} />
            </MKBox> 
          <Stack spacing={2} alignItems="center">   
            {
              renderInvioce()
            }
            
 
          </Stack>

            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={() => toggleModal("showHistory")}>
                close
                </MKButton>
               
            </MKBox>
            </MKBox>
            </Grid>
        </Slide>
    </Modal>


    <Modal open={modalState.showPayment} onClose={() => toggleModal("showPayment")} sx={{ display: "grid", placeItems: "center" }}>
      <Slide direction="down" in={modalState.showPayment} timeout={300}>
      <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
        <MKBox 
          sx={{ width: '95%' }}
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          borderWidth= {2}
          bgColor="white"
          shadow="xl"
          textAlign="center"
        >


          <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Payment Method</MKTypography> 
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={ () => toggleModal("showPayment")} />
          </MKBox> 
          <Stack spacing={2} alignItems="center">   

            {renderPaymentMethods()}

          </Stack>

          <Divider sx={{ my: 3 }} />
          <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={() => toggleModal("showPayment")}>
              close
              </MKButton>
              
          </MKBox>

        </MKBox>
        </Grid>
      </Slide>
    </Modal>


    <Modal open={modalState.showPlan} onClose={toggleModalPlan} sx={{ display: "grid", placeItems: "center" }}>
      <Slide direction="down" in={modalState.showPlan} timeout={300}>
      <Grid container item xs={12} md={8} lg={6} justifyContent="center" mx="auto">
          <MKBox
            sx={{ width: '95%' }}
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            borderWidth= {2}
            bgColor="white"
            shadow="xl"
            textAlign="center"
          > 

            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h4">Select a subscription plan</MKTypography> 
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModalPlan} />
            </MKBox> 
            <Stack spacing={2} alignItems="center">   
            { plans && plans.length > 0 && renderPlans()} 
            </Stack>

            <Divider sx={{ my: 4 }} />

            <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleModalPlan}>
                close
                </MKButton>
              
            </MKBox>
          </MKBox>
        </Grid>
      </Slide>
    </Modal>



    <Modal open={modalState.showAddCard} onClose={() => toggleModal("showAddCard")} sx={{ display: "grid", placeItems: "center" }}>
      <Slide direction="down" in={modalState.showAddCard} timeout={300}>
      <Grid container item xs={12} md={8} lg={6} justifyContent="center" mx="auto">
          <MKBox
            
            sx={{ width: '95%' }}
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            borderWidth= {2}
            bgColor="white"
            shadow="xl"
            textAlign="center"
            p={2}
          > 

            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h4">Please add a payment method</MKTypography> 
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => toggleModal("showAddCard")} />
            </MKBox> 
            {errorMessage !== "" &&  <MKTypography variant="boyd" color="error">errorMessage</MKTypography> }
           
            {loadingPayment && <MKBox px={4} ><div className="dotloader" ></div> </MKBox> }

            <div id="dropin-container"></div>

            <Divider sx={{ my: 4 }} />

            <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={() => toggleModal("showAddCard")}>
                close
                </MKButton>
              
                <MKButton variant="gradient" color="dark" onClick={ savePayment }>
                Save
                </MKButton>
            </MKBox>
          </MKBox>
        </Grid>
      </Slide>
    </Modal>



    <Modal open={modalState.showConfirmSubscribe} onClose={() => toggleModal("showConfirmSubscribe")} sx={{ display: "grid", placeItems: "center" }}>
      <Slide direction="down" in={modalState.showConfirmSubscribe} timeout={300}>
      <Grid container item xs={12} md={8} lg={6} justifyContent="center" mx="auto">
          <MKBox
            
            sx={{ width: '95%' }}
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            borderWidth= {2}
            bgColor="white"
            shadow="xl"
            textAlign="center"
            p={2}
          > 
          {currentSubscribe && currentSubscribe.developerPlanId != "free-plan"? 

           
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}> 
             { currentSubscribe.plan.price < selectedPlan.price ?
                <MKTypography variant="h4" color="info">Are you sure to subscribe this plan? You will upgrade  your current plan.</MKTypography> 
                :
                <MKTypography variant="h4" color="info">Are you sure to subscribe this plan? You will downgrade your current plan.</MKTypography> 
             }
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => toggleModal("showConfirmSubscribe")} />
            </MKBox>  
          :

          <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}> 
              <MKTypography variant="h4" color="info">Are you sure to subscribe this plan?</MKTypography> 
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => toggleModal("showConfirmSubscribe")} />
          </MKBox>  

          }
            <Divider sx={{ my: 2}} />

            <MKBox px={4}>
              <MKTypography variant="h4">{selectedPlan.name} USD ${selectedPlan.price}</MKTypography> 
              <MKTypography variant="h5">{selectedPlan.desc}</MKTypography> 
            </MKBox> 
            

            <Divider sx={{ my: 4 }} />

            {currentSubscribe && currentSubscribe.plan ? 
              <Stack px={4}>
                <MKTypography variant="caption" color="info">Current Plan: {currentSubscribe.plan.name} USD ${currentSubscribe.plan.price}</MKTypography>
                <MKTypography variant="caption">Remaining Day: {currentSubscribe.remainingDay}</MKTypography>  

              </Stack> 
              : null
            }

            <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={() => toggleModal("showConfirmSubscribe")}>
                cancel
                </MKButton>
              
                <MKButton variant="gradient" color="primary" onClick={ confirmSubscribe }>
                subscribe
                </MKButton>
            </MKBox>
          </MKBox>
        </Grid>
      </Slide>
    </Modal>




    <Modal open={modalState.showBillingAddress} onClose={() => toggleModal("showBillingAddress")} sx={{ display: "grid", placeItems: "center" }}>
      <Slide direction="down" in={modalState.showBillingAddress} timeout={300}>
      <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
          <MKBox 
            sx={{ width: '95%' }}
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            borderWidth= {2}
            bgColor="white"
            shadow="xl"
            textAlign="center"
          >


            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Billing Address</MKTypography> 
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => toggleModal("showBillingAddress")} />
            </MKBox> 

            <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" method="post" autocomplete="off">
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput label="Frist Name" name="firstName" fullWidth required variant="standard" defaultValue={address.firstName} onChange={onChangeAddressValue}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput label="Last Name" name="lastName" fullWidth required variant="standard" defaultValue={address.lastName} onChange={onChangeAddressValue}/>
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput label="Address" name="streetAddress" multiline fullWidth   required variant="standard" defaultValue= {address.streetAddress} onChange={onChangeAddressValue}/>
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput label="Address 2 (optional)" name="extendedAddress" multiline fullWidth variant="standard" defaultValue= {address.extendedAddress} onChange={onChangeAddressValue}/>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <MKInput label="City" fullWidth required variant="standard" name="locality" defaultValue= {address.locality} onChange={onChangeAddressValue}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput label="State/Province" fullWidth required variant="standard" name="region" defaultValue= {address.region} onChange={onChangeAddressValue}/>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <MKInput label="Zip" fullWidth required variant="standard" name="postalCode" defaultValue={address.postalCode} onChange={onChangeAddressValue}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomSelection list={countryList.list} placeholder="Select Country" defaultValue={address.countryCodeAlpha2} selectionHandler={onSelectCountry} /> 
                    </Grid>


                  </Grid>
                  
                </MKBox>
              </MKBox>
            </Grid>
            
          <Divider sx={{ my: 0 }} />

          <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={() => toggleModal("showBillingAddress")}>
                close
              </MKButton>
              
              <MKButton type="submit" variant="gradient" color="primary" onClick={saveAddress}>
                Save
              </MKButton>

          </MKBox>
          </MKBox>
          </Grid>
      </Slide>
    </Modal>

    
    </MKBox> 
  </ProfileLayout>
  </>

  );


}

export default Profile;
